.table {
    inline-size: 100%;
    border-collapse: collapse;
    font-size: 0.8125rem;
    white-space: nowrap;
  
    [align='right'] > * {
      text-align: end;
    }
    [align='center'] > * {
      text-align: center;
    }
  
    thead {
      text-transform: uppercase;
      color: var(--mui-palette-text-primary);
      border-block-start: 1px solid var(--border-color);
  
      th {
        font-weight: 500;
        font-size: 0.8125rem;
        letter-spacing: 0.2px;
        line-height: 1.8462;
        text-align: start;
        block-size: 56px;
        &:not(:first-of-type):not(:last-of-type) {
          padding-block: 0.5rem;
          padding-inline: 1rem;
        }
        &:first-of-type {
          &:not(:has(input[type='checkbox'])) {
            padding-block: 0.5rem;
            padding-inline: 1.5rem 1rem;
          }
          &:has(input[type='checkbox']) {
            padding-inline-start: 0.9375rem;
          }
        }
        &:last-of-type {
          padding-block: 0.5rem;
          padding-inline: 1rem 1.5rem;
        }
      }
    }
  
    tbody {
      color: var(--mui-palette-text-secondary);
  
      th,
      td {
        font-size: 0.9375rem;
        line-height: 1.4667;
        block-size: 50px;
        &:not(:first-of-type):not(:last-of-type) {
          padding-block: 0.5rem;
          padding-inline: 1rem;
        }
        &:first-of-type {
          &:not(:has(input[type='checkbox'])) {
            padding-block: 0.5rem;
            padding-inline: 1.5rem 1rem;
          }
          &:has(input[type='checkbox']) {
            padding-inline-start: 0.9375rem;
          }
        }
        &:last-of-type {
          padding-block: 0.5rem;
          padding-inline: 1rem 1.5rem;
        }
      }
  
      tr:not(:last-child) {
        border-block-end: 1px solid var(--border-color);
      }
      tr {
        border-block-start: 1px solid var(--border-color);
      }
    }
  }
  
  .cellWithInput input {
    inline-size: 100%;
    background-color: transparent;
    font-size: inherit;
    color: inherit;
    border-radius: var(--mui-shape-customBorderRadius-sm);
    padding-block: 6px;
    padding-inline: 10px;
    margin-inline: -10px;
  
    &:focus-visible {
      outline: 1px solid var(--mui-palette-primary-main);
    }
  }
  

.rdt_TableCol div {
    font-weight: 800;
}
.pricing-sheet{
  overflow-x: scroll;
}
.modalWrapper {
  position: absolute;
  inset: 0;
  max-width: 660px;
  margin: auto;
  height: fit-content;
  padding: 30px 50px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 5px 20px #00000047;
}

.modalWrapper h3 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

.modalWrapper .modalBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.rdt_TableCell {
  cursor: pointer;
}
.bi-close {
  position: absolute;
  top: -2px;
  right: -2px;
  border: 0;
  color: #FFF;
  font-weight: 400;
  font-size: 18px;
  padding: 10px 15px;
  background-color: #000;
  border-radius: 50%;
}
.bi-close:hover {
  background-color: #201e1e !important;
  color: #fff !important;
  scale: 1.1;
  box-shadow: 0 0 10px #0000003d;
}
.bi-close {
  position: absolute;
  top: -2px;
  right: -2px;
  border: 0;
  color: #FFF;
  font-weight: 400;
  font-size: 18px;
  padding: 10px 15px;
  background-color: #000;
  border-radius: 50%;
}

.bi-close:hover {
  background-color: #201e1e !important;
  color: #fff !important;
  scale: 1.1;
  box-shadow: 0 0 10px #0000003d;
}

.btn-approve {
  background-color: #2ccd2c;
  border: navajowhite;
  color: #fff;
}
.btn-reject {
  background-color: #ee3164;
  border: navajowhite;
  color: #fff;
}