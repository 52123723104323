.userRow {
    border-bottom-color: rgba(0, 0, 0, .12) !important;
}

.userRow td {
    padding: 6px 20px !important;
}
.table th {
    font-weight: 800 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
}
.user-list-card{
    padding-bottom: 1.5rem;
}