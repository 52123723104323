.authentication-wrapper.authentication-basic .authentication-inner{
    max-width: 100%;
}
.bi-datePicker{
    height: 40px;
}
.bi-datePicker div{
    height: 100%;
    overflow: hidden;
    padding-top: 0;
}
.bi-datePicker button{
    right: 10px;
}
.card-header{
    padding-bottom: 0;
    padding-left: 1em;
}






